import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { RichText } from 'prismic-reactjs';
import htmlSerializer from '../../utils/htmlSerializer';

import PortalVideoNew from '../Common/PortalVideoNew'
import PortalVideoUpdateNew from '../Common/PortalVideoUpdateNew';

const RenewalDocumentsSectionNewUpdate = () => {
  const data = useStaticQuery(graphql`
    query RenewalDocumentsSectionNewUpdateQuery {
      prismicYearlyRenewals {
        data {
          section_title
          section_title1
          section_description {
            richText
          }
          section_description1 {
            richText
          }
          button_text1
          button_link {
            url
          }
          video_description {
            richText
          }
          video_title
          video_type
          wistia_video_id
          vimeo_video_id
        }
      }
    }
  `)

  const doc = data.prismicYearlyRenewals

  return (
    <>
      <div className="w-full max-w-screen-lg mt-6">
        <h2 className='text-2xl md:text-3xl 2xl:text-4xl text-gray-900 font-bold'>{doc.data.section_title}</h2>
        {doc.data.section_description.richText
          ?
          <div className="mt-6">
            <RichText
              render={doc.data.section_description.richText}
              htmlSerializer={htmlSerializer}
            />
          </div>
          :
          null
        }
      </div>

      <div className='w-full max-w-screen-lg grid grid-cols-1 gap-6 xl:gap-16 mt-14 md:mt-16'>
        {doc.data.video_type === 'Vimeo'
          ?
          <PortalVideoNew
            videoID={doc.data.vimeo_video_id}
            videoTitle={doc.data.video_title}
          >
            <RichText
              render={doc.data.video_description.richText}
              htmlSerializer={htmlSerializer}
            />
          </PortalVideoNew>
          :
          <PortalVideoUpdateNew videoID={doc.data.wistia_video_id} videoTitle={doc.data.video_title}>
            <RichText render={doc.data.video_description.richText} htmlSerializer={htmlSerializer} />
          </PortalVideoUpdateNew>
        }        
      </div>

      <div className='w-full max-w-screen-lg bg-white rounded-md shadow-md mt-6 md:mt-12'>
        <div className='p-4 xl:p-8'>
          {doc.data.section_title1
          ?
          <h2 className='text-2xl text-gray-900 font-bold mb-6'>{doc.data.section_title1}</h2>
          :
          null
          }
          {doc.data.section_description1.richText
          ?
          <RichText
            render={doc.data.section_description1.richText}
            htmlSerializer={htmlSerializer}
          />
          :
          null
          }

          <div className='flex flex-col xl:flex-row'>
            <a
              href={doc.data.button_link.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="flex items-center px-4 py-2 md:px-5 md:py-3 mt-6 border border-transparent font-bold rounded-md text-sm text-white uppercase tracking-widest bg-red-800 hover:bg-red-900">
                {doc.data.button_text1}
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default RenewalDocumentsSectionNewUpdate